<template>
  <div class="w-100 maxWrapperWidth m-4">
    <!-- Selector wizard compatible con rutas de URL-->
    <StepSelector ref="stepSelector" class="mt-2 mb-2" :step="3" />

    <div class="card accordion accordion-flush mt-3" id="accordionFlushExample">
      <!-- ITEM 1 del accordion -->
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingOne">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseOne"
            aria-expanded="false"
            aria-controls="flush-collapseOne"
          >
            <i class="fa-solid fa-bolt me-2"></i> Producción energética
          </button>
        </h2>
        <div
          id="flush-collapseOne"
          class="accordion-collapse collapse"
          aria-labelledby="flush-headingOne"
        >
          <div class="accordion-body p-4">
            <EnergyProduction
              ref="energyproduction"
              :dataSet="v_dataSet"
              :viewMode="true"
            />
          </div>
        </div>
      </div>
      <!-- ITEM 2 del accordion -->
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingTwo">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseTwo"
            aria-expanded="false"
            aria-controls="flush-collapseTwo"
          >
            <i class="fa-solid fa-arrow-up-from-ground-water me-2"></i>
            Procedencia de los volúmenes
          </button>
        </h2>
        <div
          id="flush-collapseTwo"
          class="accordion-collapse collapse"
          aria-labelledby="flush-headingTwo"
        >
          <div class="accordion-body p-4">
            <EnergySource
              ref="energysource"
              :dataSet="v_dataSet"
              :viewMode="true"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Metemos el contenido en un BlockFull, que le dará un aspecto de sección -->
    <BlockFull
      :titulo="'Plan de aducción para ' + v_selectedYear"
      :titleCol="8"
      class="mt-3"
    >
      <template v-slot:options class="col-2">
        <SavePlanButtons :redirectToFavorites="true" />
      </template>
      <template v-slot:content>
        <!-- INIT Muestreo de datos finales-->
        <BestSolutionTabs ref="bestSolutionTabs" :viewMode="false" :dataSetFromDatabase="false" />
        <!-- END Muestreo de datos finales-->
      </template>
    </BlockFull>
    <!-- Segunda fila -->

    <!-- INIT Selector avance -->
    <div class="mt-3">
      <StepActions :step="3" />
    </div>
    <!-- END Selector avance -->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import StepSelector from "./../../common/Steps-selector.vue";
import StepActions from "./../../common/Steps-actions.vue";
import BlockFull from "@/components/commons/BlockFull";
import BestSolutionTabs from "./../../common/step3-bestplan/BestSolutionTabs";
import EnergyProduction from "./../../common/step3-bestplan/EnergyProduction";
import EnergySource from "./../../common/step3-bestplan/EnergySource";
import SavePlanButtons from "../../common/step3-bestplan/SavePlanButtons.vue";

export default {
  data() {
    return {};
  },
  components: {
    BlockFull,
    StepSelector,
    StepActions,
    BestSolutionTabs,
    SavePlanButtons,
    EnergyProduction,
    EnergySource,
  },
  computed: {
    ...mapState("planner", ["v_selectedYear", "v_dataSet"]),
  },
  methods: {},
  async mounted() {},
};
</script>